import { useState } from 'react'
import { TextField, Box, List, ListItem, Typography, CircularProgress, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { getBlogs } from '../../utils/queries'
import { DocumentData, getDocs } from 'firebase/firestore'
import { Blog } from '../../models/Blog'
import { getBlogPreviewText } from '../../utils/blogFormFactory'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'

const BlogSearch = ({ onClose }: { onClose: () => void }) => {
	const navigate = useNavigate()
	const [query, setQuery] = useState('')
	const [results, setResults] = useState<Blog[]>([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<null | string>(null)

	const handleSearch = async () => {
		if (!query.trim()) {
			setResults([])
			return
		}
		setLoading(true)
		setError(null)

		try {
			const q = getBlogs(query)
			const snapshot = await getDocs(q)
			const blogsRes = snapshot?.docs.map((doc: DocumentData) => ({ ...doc.data(), id: doc.id })) as Blog[]
			setResults(blogsRes)
		} catch (err) {
			setError('Failed to fetch results. Please try again.')
		} finally {
			setLoading(false)
		}
	}

	const hanldeGoToBlog = (blog: Blog) => {
		onClose()
		navigate(`${ROUTES.BLOGS}/${blog.category}/${blog.slug}`)
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleSearch()
		}
	}

	const clearSearch = () => {
		setQuery('')
		setResults([])
		setError('')
	}

	return (
		<Box
			sx={{
				p: 2,
				position: 'fixed',
				inset: 0,
				top: '70px',
				zIndex: 100,
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				overflow: 'hidden'
			}}
		>
			<Box textAlign="end">
				<IconButton onClick={onClose}>
					<ClearIcon />
				</IconButton>
			</Box>
			<Box sx={{ display: 'flex', flexGrow: '1', overflow: 'hidden', alignItems: 'center', flexDirection: 'column' }}>
				{error ? (
					<Typography color="error" sx={{ mt: 2 }}>
						{error}
					</Typography>
				) : (
					<Box sx={{ display: 'flex', width: '100%', overflow: 'hidden', maxWidth: '700px', flexDirection: 'column', flexGrow: '1', gap: 2 }}>
						<Typography>
							<b>Search Blogs by Title</b>
						</Typography>
						<Box sx={{ position: 'relative', width: '100%', margin: 'auto' }}>
							<TextField
								sx={{ width: '100%' }}
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								onKeyDown={handleKeyDown} // Add ENTER key event handler
								placeholder="Search..."
								variant="outlined"
								InputProps={{
									startAdornment: (
										<IconButton onClick={handleSearch} edge="start">
											<SearchIcon />
										</IconButton>
									),
									endAdornment: query && (
										<IconButton onClick={clearSearch} edge="end">
											<ClearIcon />
										</IconButton>
									)
								}}
							/>
						</Box>
						<List sx={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1', overflow: 'auto' }}>
							{loading ? (
								<CircularProgress />
							) : results.length && query ? (
								results.map((blog, index) => (
									<ListItem
										onClick={() => hanldeGoToBlog(blog)}
										key={index}
										sx={{
											'&:hover': { background: 'darkgrey' },
											cursor: 'pointer',
											display: 'flex',
											alignItems: 'flex-start',
											flexDirection: 'column',
											borderBottom: '1px solid #ccc'
										}}
									>
										<Typography>
											<b>{blog.title}</b>
										</Typography>
										<Typography>{getBlogPreviewText(blog.details)}</Typography>
									</ListItem>
								))
							) : null}
						</List>
					</Box>
				)}
			</Box>
		</Box>
	)
}

export default BlogSearch
