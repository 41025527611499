/* eslint-disable */
import { FC, useEffect, useRef, useState } from 'react'
import { getBlogBySlugQuery, getBlogsQuery, getRelatedBlogsQuery } from '../../utils/queries'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { Blog } from '../../models/Blog'
import { toast } from 'react-toastify'
import BlogsBanner from '../../assets/Blogs/blogs-banner.jpg'
import PureReactCarousel from '../../components/PureReactCarousel/ReactPureCarousel'
import { Slide } from 'pure-react-carousel'
import { getResponsiveNoOfSlides } from '../../utils/getResponsiveNoOfSildes'
import parser from 'html-react-parser'
import { DocumentData, collection, getCountFromServer, getDocs, query } from 'firebase/firestore'
import { db } from '../../firebase'
import { getBlogPreviewText } from '../../utils/blogFormFactory'
import BlogTitleListing from '../../components/BlogCards/BlogTitleListing'
import BlogPreviewCard from '../../components/BlogCards/BlogPreviewCard'

const BLOGS_PER_PAGE = 10

const Blogs: FC = () => {
	const [blogs, setBlogs] = useState<Blog[]>([])
	const [latestBlogs, setLatestBlogs] = useState<Blog[]>([])
	const [relatedBlogs, setRelatedBlogs] = useState<Blog[]>([])
	const [selectedBlogIndex, setSelectedBlogIndex] = useState<number | undefined>()
	const [totalBlogCount, setTotalBlogCount] = useState(0)
	const [selectedBlog, setSelectedBlog] = useState<Blog | null>(null)

	const { slug, category } = useParams<{ slug: string; category: string }>()
	const location = useLocation()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const firstDoc = useRef<DocumentData | null>(null) // First document of the current page
	const lastDoc = useRef<DocumentData | null>(null) // Last document of the current page

	const isSelectedOnceRef = useRef(false)
	const totalPages = Math.ceil((totalBlogCount ?? 0) / BLOGS_PER_PAGE)

	const fetchData = async (direction?: 'next' | 'back') => {
		setLoading(true)
		try {
			let initialLoad = false
			let q
			if (direction === 'back') {
				setCurrentPage((p) => p - 1)
				q = getBlogsQuery(BLOGS_PER_PAGE, 'back', firstDoc.current!)
			} else if (direction === 'next') {
				setCurrentPage((p) => p + 1)
				q = getBlogsQuery(BLOGS_PER_PAGE, 'next', lastDoc.current!)
			} else {
				initialLoad = true
				q = getBlogsQuery(BLOGS_PER_PAGE)
			}

			const snapshot = await getDocs(q)
			firstDoc.current = snapshot.docs[0] // Set first document of this page
			lastDoc.current = snapshot.docs[snapshot.docs.length - 1] // Set last document of this page

			const blogsRes = snapshot?.docs.map((doc: DocumentData) => ({ ...doc.data(), id: doc.id })) as Blog[]
			if (initialLoad) {
				setLatestBlogs([...blogsRes])
			}
			setBlogs([...blogsRes])
		} catch (error) {
			console.error('Error fetching data: ', error)
		} finally {
			setLoading(false)
		}
	}

	const getBlogCount = async () => {
		const ref = collection(db, 'blogs')
		const snapshot = await getCountFromServer(query(ref))
		setTotalBlogCount(snapshot.data().count)
	}

	const getRelatedBlog = async (category: string, tags: string[] = []) => {
		const query = getRelatedBlogsQuery(category)
		const documentSnapshots = await getDocs(query)
		let relatedBlogs = documentSnapshots?.docs.map((doc: DocumentData) => ({
			...doc.data(),
			id: doc.id
		})) as Blog[]
		if (slug) {
			let selectedBlogIndex
			let blog
			const limitedBlogs: Blog[] = []
			relatedBlogs.forEach((b, i) => {
				if (limitedBlogs.length < 4) {
					limitedBlogs.push(b)
				}
				if (b.slug === slug) {
					selectedBlogIndex = i
					blog = b
					return true
				}
			})
			if (selectedBlogIndex !== undefined) {
				relatedBlogs = limitedBlogs
				setSelectedBlog(blog ?? null)
				setSelectedBlogIndex(selectedBlogIndex)
			} else {
				navigate(ROUTES.BLOGS)
			}
		}
		setRelatedBlogs(relatedBlogs)
	}

	const blogSelectionHandler = (blog: Blog) => {
		setSelectedBlog(blog)
		navigate(`${ROUTES.BLOGS}/${blog.category}/${blog.slug}`)
	}

	const relatedBlogSelectionHandler = (blogIndex: number) => {
		setSelectedBlogIndex(blogIndex)
		const selectedBlog = relatedBlogs[blogIndex]
		setSelectedBlog(selectedBlog)
		navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
	}

	useEffect(() => {
		fetchData()
		getBlogCount()
	}, [])

	useEffect(() => {
		if (location.pathname === ROUTES.BLOGS) setSelectedBlogIndex(undefined)
	}, [location.pathname])

	const nextBlogHandler = () => {
		if (selectedBlogIndex! < relatedBlogs.length - 1) {
			const selectedBlog = relatedBlogs[selectedBlogIndex! + 1]
			navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
			setSelectedBlog(selectedBlog)
			setSelectedBlogIndex((p) => p! + 1)
		}
	}
	const prevBlogHandler = () => {
		if (selectedBlogIndex! > 0) {
			const selectedBlog = relatedBlogs[selectedBlogIndex! - 1]
			navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
			setSelectedBlog(selectedBlog)
			setSelectedBlogIndex((p) => p! - 1)
		}
	}

	const copyLinkHandler = () => {
		const selectedBlog = blogs[selectedBlogIndex!]
		const link = `${window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')}${ROUTES.BLOGS}/${selectedBlog.category}/${
			selectedBlog.slug
		}`
		navigator.clipboard.writeText(link)
		toast.success('Blog Link copied successfully')
	}

	const { noOfSlidesForBlogs } = getResponsiveNoOfSlides()

	useEffect(() => {
		if (selectedBlog) {
			isSelectedOnceRef.current = true
			getRelatedBlog(selectedBlog?.category, selectedBlog.tags)
		}

		if (!selectedBlog && !isSelectedOnceRef.current && category) {
			isSelectedOnceRef.current = true
			getDocs(getBlogBySlugQuery(slug ?? '')).then((docs) => {
				getRelatedBlog(category, docs.docs.at(0)?.data()?.tags ?? [])
			})
		}
	}, [selectedBlog?.id, slug])

	useEffect(() => {
		if (!slug) setSelectedBlog(null)
	}, [slug])

	const [firstBlog, ...restBlogs] = blogs ?? []

	if (slug && category && !selectedBlog) return null

	if (!slug && !firstBlog) return <Typography>Loading...</Typography>

	return (
		<Container className="p-0" maxWidth="xl">
			<Box sx={{ minHeight: '100vh' }}>
				{!selectedBlog ? (
					<Box sx={{ padding: '1rem', display: 'flex', gap: '1rem', flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="h4">
								<b>Blogs</b>
							</Typography>
							<Box sx={{ marginLeft: 'auto', display: 'flex', gap: '10px', flexDirection: 'row', width: 200 }}>
								<Button disabled={loading || currentPage <= 1} onClick={() => fetchData('back')}>
									Previous
								</Button>
								<Button disabled={loading || currentPage >= totalPages} onClick={() => fetchData('next')}>
									Next
								</Button>
							</Box>
						</Box>

						<Box
							sx={{
								gap: '1rem',
								display: 'grid',
								gridTemplateColumns: {
									xs: '1fr',
									sm: '1fr 1fr',
									md: '1fr 1fr 1fr'
								}
							}}
						>
							<Box onClick={() => blogSelectionHandler(firstBlog)} sx={{ gridColumn: '1 / span 2' }}>
								<Box
									sx={{
										backgroundImage: `url(${firstBlog?.background_title_image})`,
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										height: {
											xs: '200px',
											md: '400px'
										},
										width: '100%',
										cursor: 'pointer'
									}}
								/>
								<Box sx={{ background: '#333333', color: 'white', padding: '10px' }}>
									<Typography variant="h5">{firstBlog?.title}</Typography>
									<Typography>{getBlogPreviewText(firstBlog?.details, 250)}</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr' },
									gap: '1rem',
									gridColumn: { xs: '1 / span 1', sm: '1 / span 2', md: '3 / span 1' },
									flexDirection: { xs: 'row', md: 'column' }
								}}
							>
								<Box sx={{ padding: '10px' }}>
									<BlogTitleListing blogs={latestBlogs.slice(0, 3)} listingType="Latest Blogs" onSelectBlog={blogSelectionHandler} />
								</Box>
								<Box sx={{ padding: '10px' }}>
									<BlogTitleListing blogs={blogs.slice(-3)} listingType="Popular Blogs" onSelectBlog={blogSelectionHandler} />
								</Box>
							</Box>
						</Box>
						<Box sx={{ display: 'grid', gap: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' } }}>
							{restBlogs.map((blog, i) => (
								<BlogPreviewCard blog={blog} onSelectBlog={blogSelectionHandler} />
							))}
						</Box>
					</Box>
				) : (
					<Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								height: '600px',
								maxHeight: { xs: '300px', sm: '400px', md: '450px', lg: '500px', xl: '600px' },
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								backgroundImage: `url(${selectedBlog?.background_title_image ? selectedBlog?.background_title_image : BlogsBanner})`,
								padding: '20px',
								backgroundPosition: 'center'
							}}
						>
							<Typography
								variant="h1"
								sx={{
									background: '#ffffff29',
									padding: '10px 20px',
									border: '3px solid #ffffff61',
									textAlign: 'center',
									color: 'white',
									fontSize: { xs: '30px', md: '50px', lg: '70px' },
									fontWeight: 'bold'
								}}
							>
								{selectedBlog ? selectedBlog.title : 'BLOGS'}
							</Typography>
						</Box>
						<Container maxWidth="xl" className="py-5">
							<Grid container spacing={2}>
								<Grid order={{ xs: 2, md: 1 }} item xs={12} md={8}>
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
										<Box textAlign="end">
											<Button
												sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
												onClick={prevBlogHandler}
												disabled={selectedBlogIndex! <= 0}
												type="button"
												variant="text"
											>
												{'< Previous'}
											</Button>
											<Button
												sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
												onClick={nextBlogHandler}
												disabled={selectedBlogIndex! >= relatedBlogs.length - 1}
												type="button"
											>
												{'Next >'}
											</Button>
										</Box>
										<Box sx={{ alignSelf: 'start', display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '20px' }}>
											<Box>
												<img style={{ maxWidth: '100%' }} src={selectedBlog.preview_url} />
											</Box>
											<Typography>
												<b>Updated Date :</b>{' '}
												{selectedBlog.createdAt !== undefined && selectedBlog.createdAt !== null && selectedBlog.createdAt
													? new Date(selectedBlog.createdAt.seconds * 1000).toDateString()
													: undefined}
											</Typography>
											{selectedBlog.details ? <Box className="container-image">{parser(selectedBlog.details)}</Box> : null}
											{selectedBlog?.tags && selectedBlog?.tags?.length ? (
												<Box style={{ marginTop: 5, marginBottom: 5, color: 'rgb(0, 98, 255)', display: 'flex', flexDirection: 'column', gap: '10px' }}>
													{selectedBlog?.tags?.map((tag) => (
														<Typography>#{tag}</Typography>
													))}
												</Box>
											) : null}
											<Typography sx={{ background: '#f6f6f6', padding: '5px 20px' }}>
												<b>Share by</b>
												<Button onClick={copyLinkHandler}>Copying Link</Button>
											</Typography>
										</Box>
										{relatedBlogs.length > 1 ? (
											<Box>
												<Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Related Blogs</Typography>
												<hr />
												<PureReactCarousel CarouselProviderClassName="carousel-provider" totalSlides={3} visibleSlides={noOfSlidesForBlogs} showCarouselButtons={false}>
													{relatedBlogs?.map((blog, i) => {
														if (blog.id === selectedBlog.id) return null
														return (
															<Slide key={blog.slug} index={i}>
																<Box sx={{ padding: '10px 20px', maxWidth: '100%', height: '100%' }} key={blog.slug}>
																	<BlogPreviewCard
																		previewTextLimit={100}
																		wrapperStyles={{ height: '100%', background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))' }}
																		blog={blog}
																		onSelectBlog={() => relatedBlogSelectionHandler(i)}
																	/>
																</Box>
															</Slide>
														)
													})}
												</PureReactCarousel>
											</Box>
										) : null}
									</Box>
								</Grid>
								<Grid order={{ xs: 1, md: 2 }} item xs={12} md={4}>
									<BlogTitleListing blogs={latestBlogs.slice(0, 5)} onSelectBlog={blogSelectionHandler} listingType="Latest Blogs" />
								</Grid>
							</Grid>
						</Container>
					</Box>
				)}
			</Box>
		</Container>
	)
}
export default Blogs
