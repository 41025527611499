import { Box, SxProps, Theme, Typography } from '@mui/material'
import { Blog } from '../../models/Blog'
import { getBlogPreviewText } from '../../utils/blogFormFactory'

const BlogPreviewCard = ({
	blog,
	onSelectBlog,
	wrapperStyles = {},
	previewTextLimit = 150
}: {
	blog: Blog
	onSelectBlog: (blog: Blog) => void
	wrapperStyles?: SxProps<Theme>
	previewTextLimit?: number
}) => {
	return (
		<Box onClick={() => onSelectBlog(blog)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', ...wrapperStyles }}>
			<Box
				sx={{
					backgroundImage: `url(${blog?.background_title_image})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					width: '100%',
					height: '200px',
					maxHeight: '200px'
				}}
			/>
			<Box sx={{ background: '#333333', color: 'white', padding: '10px', flexGrow: 1 }}>
				<Typography variant="h5">{blog?.title}</Typography>
				<Typography>{getBlogPreviewText(blog?.details, previewTextLimit)}</Typography>
			</Box>
		</Box>
	)
}

export default BlogPreviewCard
