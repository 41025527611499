import { Box, Typography } from '@mui/material'
import { Blog } from '../../models/Blog'

const BlogTitleListing = ({ blogs, listingType, onSelectBlog }: { blogs: Blog[]; listingType: string; onSelectBlog: (blog: Blog) => void }) => {
	return (
		<Box sx={{ padding: '10px' }}>
			<Typography variant="h6" sx={{ padding: '5px', borderBlock: '3px solid' }}>
				<b>{listingType}</b>
			</Typography>
			<Box sx={{ color: 'grey' }}>
				{blogs.map((blog) => (
					<Box key={blog.id} onClick={() => onSelectBlog(blog)} sx={{ '&:hover': { color: '#0062FF' }, cursor: 'pointer', padding: '5px', borderBottom: '1px solid' }}>
						<Typography variant="h6">{blog.title}</Typography>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default BlogTitleListing
