import { FC, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_PROJECT_ID === 'gambitresume-prod' ? 'G-HW6Q9X5RJ9' : process.env.REACT_APP_MEASUREMENT_ID

const GoogleAnalytics: FC = () => {
	const location = useLocation()

	useEffect(() => {
		ReactGA.initialize(`${GOOGLE_ANALYTICS_ID}`)
	}, [])

	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
	}, [location])

	return null
}

export default GoogleAnalytics
